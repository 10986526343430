import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoadsComponent } from './pages/loads/loads.component';
import { MapComponent } from './pages/map/map.component';
import { DispatchComponent } from './pages/manage-pages/dispatch/dispatch.component';
import { CarrierComponent } from './pages/manage-pages/carrier/carrier.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SafetyComponent } from './pages/safety/safety.component';
import { UsersComponent } from './pages/users/users.component';
import { SupportComponent } from './pages/support/support.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { HttpClientModule } from '@angular/common/http';


import { DuplicateDriversComponent } from './pages/manage-pages/carrier/components/duplicate-drivers/duplicate-drivers.component';
import { DuplicateEquipmentComponent } from './pages/manage-pages/carrier/components/duplicate-equipment/duplicate-equipment.component';
import { DuplicatedDriversComponent } from './pages/manage-pages/carrier/components/duplicated-drivers/duplicated-drivers.component';
import { ReassignDriverComponent } from './pages/manage-pages/carrier/components/reassign-driver/reassign-driver.component';
import { ReassignEquipmentComponent } from './pages/manage-pages/carrier/components/reassign-equipment/reassign-equipment.component';
import { ReassignedDriverComponent } from './pages/manage-pages/carrier/components/reassigned-driver/reassigned-driver.component';
import { ReassignedEquipmentComponent } from './pages/manage-pages/carrier/components/reassigned-equipment/reassigned-equipment.component';
import { CheckboxComponent } from './pages/manage-pages/dispatch/components/checkbox/checkbox.component';
import { ConnectionItemComponent } from './pages/manage-pages/dispatch/components/connection-item/connection-item.component';
import { ConnectionComponent } from './pages/manage-pages/dispatch/components/connection/connection.component';
import { DeleteConnectionsComponent } from './pages/manage-pages/dispatch/components/delete-connections/delete-connections.component';
import { DuplicateToUsersComponent } from './pages/manage-pages/dispatch/components/duplicate-to-users/duplicate-to-users.component';
import { DuplicatedSuccessfullyComponent } from './pages/manage-pages/dispatch/components/duplicated-successfully/duplicated-successfully.component';
import { ReassignToUserComponent } from './pages/manage-pages/dispatch/components/reassign-to-user/reassign-to-user.component';
import { SelectDuplicatedDriversScopeComponent } from './pages/manage-pages/dispatch/components/select-duplicated-drivers-scope/select-duplicated-drivers-scope.component';
import { SuccessSnackbarComponent } from '../../components/snackbars/success-snackbar/success-snackbar.component';
import { ReusableTableComponent } from './pages/users/components/reusable-table/reusable-table.component';
import { ButtonBrandComponent } from '../../components/button-brand/button-brand.component';
import { ButtonOutlinedComponent } from '../../components/button-outlined/button-outlined.component';
import { CreateUserPopupComponent } from './pages/users/components/create-user-popup/create-user-popup.component';
import { InviteUserPopupComponent } from './pages/users/components/invite-user-popup/invite-user-popup.component';
import { TimelineModule } from './pages/timeline/timeline.module';
import { CreateUserFormComponent } from './pages/users/components/create-user-form/create-user-form.component';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { EditUserFormComponent } from './pages/users/components/edit-user-form/edit-user-form.component';
import { EditUserPopupComponent } from './pages/users/components/edit-user-popup/edit-user-popup.component';
import { EditCarrierFormComponent } from './pages/timeline/components/edit-carrier-form/edit-carrier-form.component';
import { EditDriverFormComponent } from './pages/timeline/components/edit-driver-form/edit-driver-form.component';
import { EditTrailerPopupComponent } from './edit-trailer-popup/edit-trailer-popup.component';
import { EditVehiclePopupComponent } from './edit-vehicle-popup/edit-vehicle-popup.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SharedFileUploaderModule } from '../../components/file-uploader/shared.file-uploader-module.ts';
import { TooltipModule } from '../../directives/tooltip.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DebounceClickDirective } from '../../directives/debounce-click.directive';
import { MatRippleModule } from '@angular/material/core';
import { RecycleBinComponent } from './pages/manage-pages/dispatch/components/recycle-bin/recycle-bin.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { DividerModule } from 'primeng/divider';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule as PTooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const MATERIAL = [
  MatFormFieldModule,
  MatSelectModule,
  MatTabsModule,
  MatRadioModule,
  MatIconModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatDividerModule,
  MatInputModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatRippleModule,
  MatProgressSpinnerModule,
]

const primeNg = [
  DropdownModule,
  InputTextModule,
  SplitButtonModule,
  TieredMenuModule,
  OverlayPanelModule,
  ListboxModule,
  DividerModule,
  MultiSelectModule,
  CalendarModule,
  InputSwitchModule,
  PTooltipModule,
  ToastModule
]

@NgModule({
  declarations: [
    DashboardComponent,
    LoadsComponent,
    MapComponent,
    DispatchComponent,
    CarrierComponent,
    ReportsComponent,
    SafetyComponent,
    UsersComponent,
    SupportComponent,
    SettingsComponent,
    CarrierComponent,
    DuplicateEquipmentComponent,
    DuplicateDriversComponent,
    DuplicatedDriversComponent,
    ReassignEquipmentComponent,
    ReassignedEquipmentComponent,
    ReassignDriverComponent,
    ReassignedDriverComponent,
    DispatchComponent,
    DuplicateToUsersComponent,
    ReassignToUserComponent,
    DeleteConnectionsComponent,
    ConnectionComponent,
    DuplicatedSuccessfullyComponent,
    SelectDuplicatedDriversScopeComponent,
    CheckboxComponent,
    ConnectionItemComponent,
    ReusableTableComponent,
    CreateUserPopupComponent,
    InviteUserPopupComponent,
    CreateUserFormComponent,
    DialogComponent,
    EditUserFormComponent,
    EditUserPopupComponent,
    EditCarrierFormComponent,
    EditDriverFormComponent,
    EditTrailerPopupComponent,
    EditVehiclePopupComponent,
    RecycleBinComponent
  ],
  imports: [
    TooltipModule,
    [...MATERIAL],
    [...primeNg],
    ButtonBrandComponent,
    BrowserAnimationsModule,
    ButtonOutlinedComponent,
    DebounceClickDirective,
    CommonModule,
    MainRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    TimelineModule,
    SuccessSnackbarComponent,
    NgxIntlTelInputModule,
    HttpClientModule,
    SharedFileUploaderModule,
    MatSelectCountryModule.forRoot('en'),
    AngularSvgIconModule.forRoot()
  ],
  providers: [MatDatepickerModule, MessageService]
})
export class MainModule { }
