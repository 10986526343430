<div class="popup-wrapper" [ngClass]="{ 'custom-height': connections.length === 0 }">

    <div class="header">
        <div class="img-block">
            <img src="assets/svg/history.svg" alt="">
        </div>
        <h2 class="title">Share history</h2>
        <button class="close" (click)="close()">
            <img src="assets/svg/x-close.svg" alt="">
        </button>
    </div>

    <div class="example">
        <div class="super ex">
            <svg-icon src="assets/svg/supervisor-icon.svg"></svg-icon>
            <p>Supervisor</p>
        </div>
        <div class="disp ex">
            <svg-icon src="assets/svg/dispatcher-icon.svg"></svg-icon>
            <p>Dispatcher</p>
        </div>
        <div class="driver ex">
            <svg-icon src="assets/svg/steering-icon.svg"></svg-icon>
            <p>Driver</p>
        </div>
        <div class="was-sent-to ex">
            <p>Was sent</p>
            <svg-icon src="assets/svg/long-arrow-icon.svg"></svg-icon>
        </div>
    </div>


    <div class="filtration-wrapper">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h2 class="title">Filtration</h2>
                </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="filters">
                    <mat-form-field appearance="outline" class="custom-multi-input-cont">
                        <mat-label>Author</mat-label>
                        <mat-select [(ngModel)]="selectedAuthor" multiple panelClass="dropDwonMultipleFixClass">
                        @for (author of authorsList; track $index) {
                          <mat-option id="allOption" [value]="author" (click)="toggleAuthor(author)">
                            <input [id]="'ms'+$index" class="custom-checkbox" type="checkbox" [checked]="author.selected"
                              (change)="toggleAuthor(author)"><label [for]="'ms'+$index"></label>
                            <label class="dl-label-style">{{ author.fromName }}</label>
                          </mat-option>
                          }
                        </mat-select>
                        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="custom-multi-input-cont">
                        <mat-label>From</mat-label>
                        <mat-select [(ngModel)]="selectedFrom" multiple panelClass="dropDwonMultipleFixClass">
                        @for (from of fromList; track $index) {
                          <mat-option id="allOption" [value]="from" (click)="toggleFrom(from)">
                            <input [id]="'ms'+$index" class="custom-checkbox" type="checkbox" [checked]="from.selected"
                              (change)="toggleFrom(from)"><label [for]="'ms'+$index"></label>
                            <label class="dl-label-style">{{ from.sharedUserName }}</label>
                          </mat-option>
                          }
                        </mat-select>
                        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="custom-multi-input-cont">
                        <mat-label>Author</mat-label>
                        <mat-select [(ngModel)]="selectedTo" multiple panelClass="dropDwonMultipleFixClass">
                        @for (to of toList; track $index) {
                          <mat-option id="allOption" [value]="to" (click)="toggleTo(to)">
                            <input [id]="'ms'+$index" class="custom-checkbox" type="checkbox" [checked]="to.selected"
                              (change)="toggleTo(to)"><label [for]="'ms'+$index"></label>
                            <label class="dl-label-style">{{ to.toName }}</label>
                          </mat-option>
                          }
                        </mat-select>
                        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
                    </mat-form-field>
                </div>
        
              </mat-expansion-panel>
        </mat-accordion>
         @defer (when !isLoading) {
           <div class="connections-list">
             @if(connections.length > 0) {
               @for (connection of connections; track $index) {
                   <app-connection-item
                       [connection]="connection"
                   ></app-connection-item>
               }
             } @else {
               <div class="empty-conn">
                 <svg-icon src="assets/svg/search-faded-icon.svg"></svg-icon>
                 <p>No share history found</p>
               </div>
             }
           </div>
         }
    </div>
    @if(connections.length) {
      <div class="buttons">
          <button class="btn cancel" (click)="closeBtnClick()">Cancel</button>
          <button class="btn delete" (click)="deleteConnection()" [disabled]="isDeleteDisabled()" [ngClass]="{ 'disabled-btn': isDeleteDisabled() }">Delete</button>
      </div>
    }

</div>