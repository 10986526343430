<div class="tree-wrapper">

  <img class="main-icon" src="assets/svg/traxspere.svg" alt="">

  <!-- <app-input type="text" [value]="searchValue" [func]="filterButtons" imageSrc="assets/svg/search-icon.svg" placeholder="Search"></app-input> -->

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node 
      *matTreeNodeDef="let node; when: !hasChild" 
      matTreeNodePadding
      class="tree-node"
      [ngClass]="{ 'child': node.level, 'hidden': !node.name && !node.link }"
      (click)="navigate(node.link)"
    >
      <img class="img" *ngIf="node.src" [src]="node.src" alt="">
      <p class="p">{{ node.name }}</p>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node 
      *matTreeNodeDef="let node; when: hasChild" 
      matTreeNodePadding
      matTreeNodeToggle
      (click)="node.isExpanded = !node.isExpanded"
      class="tree-node-expandable"
      [attr.aria-label]="'Toggle ' + node.name"
    >
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
      <p class="p">{{ node.name }}</p>
      <img class="img" [src]="node.src" alt="">
    </mat-tree-node>
  </mat-tree> 

  <div class="trial-block">
    <div>
      <h6>Trial period</h6>
      <p class="left">5 out of 7 days<span class="text"> of your trial period have already expired</span></p>
    </div>
    <div class="progress-block">
      <div class="progress"></div>
    </div>
    <div class="can-do">
      <p class="dismiss">Dismiss</p>
      <p class="upgrade">Upgrade plan</p>
    </div>
  </div>

  <div class="hr"></div>

  <div class="user-block">
    <img class="img" src="assets/svg/Avatar.svg" alt="">
    <div class="user-info">
      <div class="user-container">
        <p class="name">{{userfullName}}</p>
        <img class="logout-img" src="assets/svg/logout.svg" alt="" (click)="logout()">
      </div>
      <p class="email">{{userEmail}}</p>
    </div>
  </div>
</div>
