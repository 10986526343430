<div class="popup-wrapper">
    <div class="popup-header-wrapper">
        <div class="popup-header-container">
            <div class="header-icon">
                <img src="../../../../.././../../assets/svg/users-plus.svg" alt="users-plus">
            </div>
            <div class="popup-header-title">
                <div class="header-title">Invite users</div>
            </div>
        </div>
    </div>
    <form [formGroup]="form" class="invite-form">
        <div class="form-caption">Email address</div>
            <div formArrayName="items" class="popup-content-wraper">
                    <div *ngFor="let item of items.controls; let i=index" class="popup-content-container" [formGroupName]="i">
                        <div class="block-input-icon-with-err email-user-input">
                            <svg-icon src="../../../../../../../assets/svg/mail.svg"></svg-icon>
                            <input formControlName="email" type="email" mode="Search" placeholder="mail@gmail.com" />
                            <div class="input-err" *ngIf="item.get('email').invalid && item.get('email').touched">
                                <div *ngIf="item.get('email').errors?.required">
                                    <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                                    Email is required
                                </div>
                                <div *ngIf="item.get('email').errors?.email">
                                    <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                                    Invalid email format
                                </div>
                            </div>
                        </div>
                        @if(form.value.items[i].roles === "Dispatcher") {
                            <mat-form-field class="global-select-input">
                                @if(!form.value.items[i].supervisorId){
                                    <mat-label>Select supervisor</mat-label>
                                }
                                <mat-select formControlName="supervisorId" >
                                  @for (supervisor of data.superVisorsSource; track $index) {
                                    <mat-option [value]="supervisor.id">{{supervisor.fullName}}</mat-option>
                                  }
                                </mat-select>
                                <mat-error class="input-err custom-err-select" *ngIf="item.get('supervisorId').invalid && item.get('supervisorId').touched">
                                    <mat-error *ngIf="item.get('supervisorId').errors?.required">
                                        <svg-icon src="../../../../../../../assets/svg/warn-usdot.svg" alt="plus"> </svg-icon>
                                        Supervisor is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        } 
                        <mat-radio-group formControlName="roles" class="global-radio-btn">
                            <mat-radio-button value="Dispatcher">Dispatcher</mat-radio-button>
                            <mat-radio-button value="Supervisor">Supervisor</mat-radio-button>
                            @if(i > 0){
                                <div class="delete-wrapper">   
                                    <button class="global-btn btn-delete" (click)="removeItem(i)">
                                        <svg-icon src="../../../../../../../assets/svg/trash.svg" alt="plus"> </svg-icon>
                                        <span class="btn-text">Delete</span>
                                    </button>
                                </div>
                            }
                        </mat-radio-group>
                    </div>
                <div class="add-wrapper">                
                    <button class="global-btn btn-addmore" (click)="addItem()">
                        <svg-icon src="../../../../../../../assets/svg/plus.svg" alt="plus"> </svg-icon>
                        <span class="btn-text">Add Another</span>
                    </button>
                </div>
            </div>

    </form>
    <div class="popup-footer-btns">
        <div class="actions-btns">
            <button class="global-btn btn-cancel" (click)="closeBtnClick()">
                <span class="btn-text">Cancel</span>
            </button>
            <button appDebounceClick (debounceClick)="okBtnClick()" class="global-btn btn-confirm" [ngClass]="{ 'disabled-btn': !form.valid }" [disabled]="!form.valid">
                <span class="btn-text">Confirm</span>
            </button>
        </div>
    </div>
</div>