import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { PopUpSubscriptBase } from '../../../../../../../services/popup-service/popup_subscribe_services';
import { LoaderService } from '../../../../../../../services/loader/loader.service';

@Component({
  selector: 'app-recycle-bin',
  templateUrl: './recycle-bin.component.html',
  styleUrls: ['./recycle-bin.component.scss', '../../../../../main.module.styles.scss']
})
export class RecycleBinComponent extends PopUpSubscriptBase implements OnInit, OnDestroy{

  constructor(
    private dialogRef: MatDialogRef<RecycleBinComponent>,
  ) {
    super(new Subject<any>());
  }

  public data: any;

  public users: any[] = [];
  public selectedRows: any[] = [];
  public filteredUsers: any[] = [];
  public filterText: string = ''; 
  public columns:Array<any> = new Array<any>();

  protected override showAPopUp(data: any): void {
    this.users = data;
    this.columns = data.columns;
    // this.popupTitle = data.popupTitle;
    this.applyFilter();
  }

  ngOnInit(): void {
    window.onresize = (e) => this.getNumbersOfRows();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.data = undefined;
  };

  onFilterChange(value: string) {
    this.filterText = value;
    this.applyFilter();
  }

  applyFilter() {
    if (this.filterText.length > 2) {
      const filterTextLower = this.filterText.toLowerCase();
  
      this.filteredUsers = this.users.filter((item) => {  
        // Generic search across all fields including nested fields
        const matches = (obj: any): boolean => {
          return Object.values(obj).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(filterTextLower);
            } else if (typeof value === 'object' && value !== null) {
              return matches(value);
            }
            return false;
          });
        };
        return matches(item);
      });
    } else {
      this.filteredUsers = [...this.users];
    }
  }

  selectRows(selectedRows: []) {
    // console.log('Selected rows:', selectedRows);
    this.selectedRows = selectedRows;
  }
  
  restoreBtnDisabled(): boolean {
    return this.selectedRows.length === 0;
  }
  
  deleteBtnDisabled(): boolean {
    return false;
  }

  closeBtnClick(){
    this._result.next({type:"close"});
  }

  deleteBtnClick(){
    this._result.next({type:"delete"});
  }

  restoreBtnClick(){
    this._result.next({type:"restore", data: this.selectedRows});
  }

  close(): void {
    this.dialogRef.close();
  }

  getNumbersOfRows(): number {
    const tableWrapper: HTMLElement = document.querySelector('.wrapper2') as HTMLElement;
    const estimatedRows: number = Math.round((tableWrapper.clientHeight - (92+50.5)) / 51)
    
    return estimatedRows < 1 ? 1 : estimatedRows;
  }

}
