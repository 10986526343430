import { Component, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Node } from './intefaces/node';
import { TREE_DATA } from './utils/TREE_DATA';
import { CookieService } from 'ngx-cookie-service';
import { StorageService, StorageTempate } from '../../../../services/storage/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private storageService: StorageService,
  ) {
    this.dataSource.data = TREE_DATA;
  }

  public userEmail: string = '';
  public userfullName: string = '';

  ngOnInit(): void {
    this.userfullName = localStorage.getItem('userfullName') || '';
    this.userEmail = localStorage.getItem('userEmail') || '';
  }

  searchValue = ''
  TREE_DATA_COPY = structuredClone(TREE_DATA)

  private _transformer = (node: Node, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      src: node.src,
      link: node.link,
      children: node.children,
      isExpanded: node.isExpanded
    };
  };

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: Node) => node.expandable;

  navigate(link: string): void {
    if (link) this.router.navigate([link])
  }

  filterButtons = (value: string): void => {
    if (!value) {
      this.dataSource.data = TREE_DATA;
    } else {
      this.TREE_DATA_COPY = JSON.parse(JSON.stringify(TREE_DATA));

      this.TREE_DATA_COPY = this.TREE_DATA_COPY.filter(el => {
        if (el.name.toLowerCase().includes(value.toLowerCase())) return el

        if (!el.children) {
          return el.name.toLowerCase().includes(value.toLowerCase());
        } else {
          el.children = el.children.filter((child) => child.name.toLowerCase().includes(value.toLowerCase()));
          if (el.children.length !== 0) {
            return el;
          }
          return el.name.toLowerCase().includes(value.toLowerCase());
        }
      });
      this.dataSource.data = this.TREE_DATA_COPY;

      this.TREE_DATA_COPY.forEach(el => {
        if (el.children && el.children.some(child => child.name.toLowerCase().includes(value.toLowerCase()))) {
          this.treeControl.expand(this.treeControl.dataNodes.find(x => x.name === el.name));
        }
      });
    }
  }

  logout() {
    this.cookieService.delete("token");
    this.router.navigate(['/auth/login-to-the-system']);
    this.storageService.clearStorage()
  }

  // filterButtons = (value: string): void => {
  //   if (value) {
  //     this.TREE_DATA_COPY = this.filterTreeData(TREE_DATA, value.toLowerCase());
  //   } else {
  //     this.TREE_DATA_COPY = structuredClone(TREE_DATA);
  //   }
  //   this.dataSource.data = this.TREE_DATA_COPY;

  //   this.treeControl.dataNodes.forEach(node => {
  //     if (node.children && node.children.some((child: Node) => child.name.toLowerCase().includes(value.toLowerCase()))) {
  //       this.treeControl.expand(this.treeControl.dataNodes.find(x => x.name === node.name));
  //     }
  //   });
  // };

  // filterTreeData = (nodes: Node[], filterValue: string): Node[] => {
  //   return nodes
  //     .map(node => {
  //       if (node.name.toLowerCase().includes(filterValue.toLowerCase())) return node
  //       if (node.name.toLowerCase().includes(filterValue.toLowerCase())) {
  //         return { ...node, children: node.children ? this.filterTreeData(node.children, filterValue) : node.children };
  //       } else if (node.children) {
  //         const filteredChildren = this.filterTreeData(node.children, filterValue);
  //         if (filteredChildren.length > 0) {
  //           node.isExpanded = true;
  //           return { ...node, children: filteredChildren, isExpanded: true };
  //         }
  //       }
  //       return null;
  //     })
  //     .filter(node => node !== null) as Node[];
  // };


}
