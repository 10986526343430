import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  constructor(
    private http: HttpClient
  ) { }

  private apiUrl = environment.apiUrl

  getVehicles(): Observable<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/vehicles');
  }

  getDeletedEquipment(): Observable<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/manage/deleted_equipment');
  }

  getDrivers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/api/drivers/all_simple_view`)
  }

  restoreEquipment(data: Array<any>): Observable<any> {
    const equipmentTypeMap = new Map<string, string>();
    let arr: any = [];

    data.forEach(equipment => {
      equipmentTypeMap.set(equipment.equipmentId.toString(), equipment.equipmentType);
    });
    let obj:any = Object.fromEntries(equipmentTypeMap.entries());
    arr.push(obj)
    
    return this.http.post(this.apiUrl+'/api/manage/restore_equipment', arr);
  }
  
}
