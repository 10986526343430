import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrailersService {

  constructor(
    private http: HttpClient
  ) { }
  private apiUrl = environment.apiUrl

  getTrailers(): Observable<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/trailers');
  }
}
