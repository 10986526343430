import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, map, Subscription } from 'rxjs';
import { DialogService } from '../../../../services/dialog/dialog.service';
import { LoaderService } from '../../../../services/loader/loader.service';
import { Load } from '../timeline/types/load';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadsService } from '../../../../services/loads/loads.service';
import { Item } from '../../../../interfaces/item';
import { Driver } from '../../../../interfaces/driver/driver';
import { DataService } from '../../../../services/reference-data/reference-data.service';
import { Status } from '../../../../enum/status.enum';
import { loadColors } from '../timeline/types/load-status-color';
import { CreateModalAllComponent } from '../timeline/modal/create-modal-all/create-modal-all.component';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { ConnectedPosition } from '@angular/cdk/overlay';
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM D',
  },
  display: {
    dateInput: 'MMM, D', // Format as "Sep 5"
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-loads',
  templateUrl: './loads.component.html',
  styleUrls: ['./loads.component.scss', '../../main.module.styles.scss'],
  providers: [
    provideMomentDateAdapter(MY_FORMATS),
  ],
})
export class LoadsComponent implements OnInit, OnDestroy{

  constructor (
    private loadsPageService: LoadsService,
    private dialog: DialogService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private dialog1: MatDialog,
    private dataService: DataService
  ) {  }

  public loads: Array<Load> =  new Array<Load>();
  public filteredLoads: any[] = [];
  public filterText: string = ''; 
  public allStatusSource: Array<any> = new Array<any>();
  public allDriversSource: Array<any> = new Array<any>();
  public allDispatchersSource: Array<any> = new Array<any>();
  public allCarriersSource: Array<any> = new Array<any>();
  public allBrokersSource: Array<any> = new Array<any>();
  private itemsSubscription: Subscription = new Subscription;

  public selectedStatus: Array<any> = new Array<any>();
  public selectedDriver: Array<any> = new Array<any>();
  public selectedDispatcher: Array<any> = new Array<any>();
  public selectedCarrier: Array<any> = new Array<any>();
  public selectedBroker: Array<any> = new Array<any>();
  loadDateStart: any = moment();
  loadDateEnd: any = moment();
  public pIndex!: number;
  public dIndex: any;
  public isLoading: boolean = true;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  public columns = [
    { field: 'loadId', header: 'Load ID' },
    { field: 'carrierName', header: 'Carrier' },
    { field: 'driverName', header: 'Driver' },
    { field: 'pickupField', header: 'PickUP' },
    { field: 'deliveryField', header: 'Delivery' },
    { field: 'loadMiles', header: 'Miles' },
    { field: 'price', header: 'Price' },
    { field: 'dispatcherField', header: 'Dispatcher' },
    { field: 'brokerField', header: 'Broker' },
    { field: 'loadStatus', header: 'Status' },
    { field: 'loadDetails', header: 'Action' },
    // { field: 'command', header: '' },
  ];

  editTooltipPos: ConnectedPosition[] = [
    {
      originX: 'end',      // End (right) of the host element
      overlayX: 'end',   // Start (left) of the overlay
      originY: 'center',   // Bottom of the host element
      overlayY: 'top',     // Top of the overlay
      offsetX: 40,
      offsetY: 15
    }
  ]

  ngOnInit(): void { 
    this.loadAllData();
    this.changeSelectedTab(0, 'pickup')
    this.changeSelectedTab(0, 'delivery')
    window.onresize = (e) => this.getNumbersOfRows();
  }

  getNumbersOfRows(): number {
    const tableWrapper: HTMLElement = document.querySelector('.wrapper2') as HTMLElement;
    const estimatedRows: number = Math.trunc((tableWrapper.clientHeight - (68+50.5)) / 52);
    return estimatedRows < 1 ? 1 : estimatedRows;
  }

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe();
  }

  loadAllData() {
    this.getAllLoads();
    this.startItemsObserver();
    forkJoin([
      this.loadsPageService.getAllDrivers(),
      this.loadsPageService.getAllDispatchers(),
      this.loadsPageService.getAllCarriers(),
      this.loadsPageService.getAllBrokers(),
    ]).subscribe(all => {
      this.allDriversSource = all[0];
      this.allDispatchersSource = all[1];
      this.allCarriersSource = all[2];
      this.allBrokersSource = all[3];
    });
  }

  log(data:any) {
    // console.log(data);
  }

  startItemsObserver() {
    this.itemsSubscription = this.dataService.items$.subscribe(items => {
      items.map(item => {
        switch (item.type) {
          case 'LoadStatusLoad': this.allStatusSource.push(item); break;
        }
      });
    });
  }
  
  onFilterChange() {
    this.applyFilter();
  }

  applyFilter() {
    // Start with the full list of loads
    let filteredData = [...this.loads];
  
    // Track if any filter is applied
    let isFilterApplied = false;
  
    // Apply text-based search
    if (this.filterText.trim().length > 2) {
      isFilterApplied = true; // A filter is applied
  
      const filterTextLower = this.filterText.toLocaleLowerCase();

      filteredData = filteredData.filter(item => {
        const matches = (obj: any): boolean => {
          return Object.values(obj).some(value => {
            switch (typeof value) {
              case 'string': return value.toLowerCase().includes(filterTextLower);
              case 'number': return value.toString().toLowerCase().includes(filterTextLower);
              case 'object': if (value !== null) return matches(value); return false; // Assuming matches is a recursive function for nested objects 
              default: return false;
            }
          });          
        };
        return matches(item);
      });
    }
  
    // Filter by status
    if (this.selectedStatus && this.selectedStatus.length > 0) {
      isFilterApplied = true; // A filter is applied
  
      filteredData = filteredData.filter(load => 
        this.selectedStatus.some((status: any) => status.code === load.status)
      );
    }
  
    // Filter by driver
    if (this.selectedDriver && this.selectedDriver.length > 0) {
      isFilterApplied = true; // A filter is applied
  
      filteredData = filteredData.filter(load => 
        this.selectedDriver.some((driver: any) => driver.id === load.driverId)
      );
    }
  
    // Filter by dispatcher
    if (this.selectedDispatcher && this.selectedDispatcher.length > 0) {
      isFilterApplied = true; // A filter is applied
  
      filteredData = filteredData.filter(load => 
        this.selectedDispatcher.some((dispatcher: any) => dispatcher.id === load.dispatcher.id)
      );
    }
  
    // Filter by carrier
    if (this.selectedCarrier && this.selectedCarrier.length > 0) {
      isFilterApplied = true; // A filter is applied
  
      filteredData = filteredData.filter(load => 
        this.selectedCarrier.some((carrier: any) => carrier.id === load.carrierId)
      );
    }
  
    // Filter by broker
    if (this.selectedBroker && this.selectedBroker.length > 0) {
      isFilterApplied = true; // A filter is applied
  
      filteredData = filteredData.filter(load => 
        load.broker ? this.selectedBroker.some((broker: any) => broker.id === load.broker.id) : false
      );
    }
  
    // Filter by date range (start and end date)
    if (this.loadDateStart && this.loadDateEnd) {
      isFilterApplied = true; // A filter is applied
  
      filteredData = filteredData.filter(load => {
        if (load.dateTimeLineBgn && load.dateTimeLineEnd) {
          const loadDeliveryDate = new Date(load.dateTimeLineBgn);
          const loadPickupDate = new Date(load.dateTimeLineEnd);
          return loadDeliveryDate >= this.loadDateStart && loadPickupDate <= this.loadDateEnd;
        }
        return false;
      });
    }
  
    // If no filters are applied, reset to the full list of loads
    if (!isFilterApplied) {
      filteredData = [...this.loads]; // Restore original data
    }
  
    // Assign the filtered data to the table
    this.filteredLoads = filteredData;
  }

  getDate = (date: string) : Date => {
    return new Date(date);
  }

  changeSelectedTab(index: any, key: string) {
    if (key === 'pickup') {
      this.pIndex = index;
    } else if (key === 'delivery') {
      this.dIndex = index;
    } 
  }
  
  getAllLoads() {
    this.loaderService.show();
    this.loadsPageService.getAllLoads().then(data => {
      this.findSmallerDate(data);
      this.loads = this.transformTableData(data);
      // this.loads = this.transformTableData(data).concat(this.transformTableData(data)).concat(this.transformTableData(data));
      this.applyFilter();
      this.loaderService.hide();
      this.isLoading = false;
      },
      error => {
        this.loaderService.hide();
        this.isLoading = false;
        console.error('Error fetching loads', error);
      }
    );
  }

  countLoads(): number {
    let parentCount = 0;
    let totalPartialCount = 0;

    const loadMap = new Map<number, Load>();

    for (const load of this.filteredLoads) {
        loadMap.set(load.loadId, load);
    }

    // Count parents and their partials
    for (const load of this.filteredLoads) {
        if (load.parentId === null) {
            parentCount++;
            // Count partials if they exist
            if (load.partial) {
                totalPartialCount += load.partial.length;
            }
        }
    }

    return  parentCount + totalPartialCount;

}

  findSmallerDate(data: any) {
    const bgnDates = data
      .map((item: any) => moment(item.dateTimeLineBgn))
      .filter((date: moment.Moment) => date.isValid());
  
    const endDates = data
      .map((item: any) => moment(item.dateTimeLineEnd))
      .filter((date: moment.Moment) => date.isValid());
  
    this.loadDateStart = moment.min(bgnDates).toDate(); // Use `moment.min` to get the smallest date and convert to JavaScript Date
    this.loadDateEnd = moment.max(endDates).toDate();   // Use `moment.max` to get the largest date and convert to JavaScript Date
  }

  createLoad(loadByFilter?: boolean) {
    let filterFields: any;
    if(loadByFilter) {
      
      filterFields = {
        status: loadByFilter ? this.selectedStatus[0]?.code : null,
        driverId: this.selectedDriver.length ? this.selectedDriver[0].id : null,
        dispatcher: this.selectedDispatcher.length ? this.selectedDispatcher[0].fullName : null,
        carrierId: this.selectedCarrier.length ? this.selectedCarrier[0].id : null,
        broker: this.selectedBroker.length ? this.selectedBroker[0].id : null,
        startDate: this.loadDateStart,
        endDate: this.loadDateEnd,
        loadsPage: true
      };
    } else null;
    
    const dialogRef = this.dialog1.open(CreateModalAllComponent, {
      data: {
        partial: false,
        index: 0,
        drivers: this.allDriversSource,
        carriers: this.allCarriersSource,
        load: filterFields,
        dispatcers: this.allDispatchersSource,
        // startDate: this.createStartDate,
        // endDate: this.createEndDate,
      },
      panelClass: 'fix-create-load-popup',
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      if(resp) this.getAllLoads();
    });
  }

  clearFilters() {
    this.selectedStatus.forEach(element => {
      element.selected = false;
    });
    this.selectedDriver.forEach(driver => {
      driver.selected = null;
    });
    this.selectedDispatcher.forEach(dispatcher => {
      dispatcher.selected = false;
    });
    this.selectedCarrier.forEach(carrier => {
      carrier.selected = false;
    });
    this.selectedBroker.forEach(broker => {
      broker.selected = false;
    });

    this.filterText = '';
    this.selectedStatus = [];
    this.selectedDriver = [];
    this.selectedDispatcher = [];
    this.selectedCarrier = [];
    this.selectedBroker = [];
    this.filteredLoads = this.loads;
  }

  refreshTable() {
    this.getAllLoads();
  }

  getLoadStatus(status:any): number{
    return this.filteredLoads.filter((f:any)=>f?.status === status.code).length;
  }

  transformTableData(data: Array<any>): Array<any> {
    return data.map(load=>{
      let pickupField: string = '';
      let deliveryField: string =  '';
      let brokerField: string = load.broker ? load.broker.businessName : '';
      let dispatcherField: string = load.dispatcher ? load.dispatcher.name : '';
      let loadStatus: string = this.convertToTitleCase(load.status);
      
        if (load.pickup && load.pickup.length > 0) {
          const pickup = load.pickup[0];
          const city = pickup.city ? pickup.city : '';    
          const stateCode = pickup.stateCode ? pickup.stateCode : '';
          const zipCode = pickup.zipCode ? pickup.zipCode : '';
          pickupField = `${this.formatData(pickup.dateScheduledFrom)} ${city}, ${stateCode} ${zipCode}`.trim();
        }
        if (load.delivery && load.delivery.length > 0) {
          const delivery = load.delivery[0];
          const city = delivery.city ? delivery.city : '';    
          const stateCode = delivery.stateCode ? delivery.stateCode : '';
          const zipCode = delivery.zipCode ? delivery.zipCode : '';
          deliveryField = `${this.formatData(delivery.dateScheduledTo)} ${city} ${stateCode} ${zipCode}`.trim();
        }
        return {
          ...load,
          pickupField: pickupField,
          deliveryField: deliveryField,
          brokerField: brokerField,
          dispatcherField: dispatcherField,
          loadStatus: loadStatus
        };
      }
    );
  }

  convertToTitleCase(status: string): string {
    return status
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  checkLoadStatus(status: string) {
    if (status in loadColors) {
      return loadColors[status];
    } else {
      return '#F9FAFB';
    }
  }

  toggleStatus = (status: any) => {
    status.selected = !status.selected;
    this.selectedStatus = this.allStatusSource.filter(s => s.selected);
    this.applyFilter();
  }

  toggleDriver = (driver: any) => {
    driver.selected = !driver.selected;
    this.selectedDriver = this.allDriversSource.filter(s => s.selected);
    this.applyFilter();
  }

  toggleDispatcher = (dispatcher: any) => {
    dispatcher.selected = !dispatcher.selected;
    this.selectedDispatcher = this.allDispatchersSource.filter(s => s.selected);
    this.applyFilter();
  }

  toggleCarrier = (carrier: any) => {
    carrier.selected = !carrier.selected;
    this.selectedCarrier = this.allCarriersSource.filter(s => s.selected);
    this.applyFilter();
  }

  toggleBroker = (broker: any) => {
    broker.selected = !broker.selected;
    this.selectedBroker = this.allBrokersSource.filter(s => s.selected);
    this.applyFilter();
  }

  dateChangeEnd(event: any){
    this.loadDateEnd = event.value;
    this.applyFilter();
  }

  formatData(data: string): string {
    return (data && data!= 'null') ? String(new Date(data).getDay()).padStart(2, '0') + '/' + String(new Date(data).getMonth()).padStart(2, '0') : '';
  }

  editRow(row: any) {

  }

  deleteRow(row: any, deleteText?: string, parentDialogRef?:any) {
  }

  selectRows(selectedRows: []) {
    // console.log('Selected rows:', selectedRows);
  }
}
