import { Component } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { UsersService } from './services/users/users.service';
import { filter, firstValueFrom, forkJoin, from, map, of, switchMap, tap, toArray } from 'rxjs';
import { DialogService } from '../../../../../services/dialog/dialog.service';
import { DuplicateToUsersComponent } from './components/duplicate-to-users/duplicate-to-users.component';
import { SelectDuplicatedDriversScopeComponent } from './components/select-duplicated-drivers-scope/select-duplicated-drivers-scope.component';
import { DuplicateService } from './services/duplicate/duplicate.service';
import { ReassignService } from './services/reassign/reassign.service';
import { Driver } from './interfaces/driver';
import { Dispatcher } from './interfaces/dispatcher';
import { Supervisor } from './interfaces/supervisor';
import { LoaderService } from '../../../../../services/loader/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { EditUserPopupComponent } from '../../users/components/edit-user-popup/edit-user-popup.component';
import { DeleteConnectionsComponent } from './components/delete-connections/delete-connections.component';
import { ConnectionsService } from './services/connections/connections.service';
import { DialogComponent } from '../../../../../components/dialog/dialog.component';
import { NotificationService } from '../../../../../services/notification.service';
import { UsersPageService } from '../../../services/users/users.service';
import { LoadsService } from '../../../../../services/loads/loads.service';
import { UserRolesCode } from '../../../../../enum/user-role.enum';
import { RecycleBinComponent } from './components/recycle-bin/recycle-bin.component';
import moment from 'moment';

interface DispatcherBlock {
  supervisorName: string,
  supervisorId: number,
  array: Dispatcher[]
}

interface DriverBlock {
  supervisorName: string
  supervisorId: number
  dispatcherName: string
  dispatcherId: number
  array: Driver[]
}

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss', '../../../main.module.styles.scss']
})
export class DispatchComponent {

  constructor(
    private usersService: UsersService,
    private usersPageService: UsersPageService,
    private dialog: DialogService,
    public dialog1: MatDialog,
    private duplicateService: DuplicateService,
    private reassignService: ReassignService,
    private loaderService: LoaderService,
    private connService: ConnectionsService,
    private notificationService: NotificationService,
    private loadsService: LoadsService
  ) { }

  allSupervisors: Supervisor[] = []
  allDispatchers: Dispatcher[] = []
  allDrivers: Driver[] = []

  supervisors: Supervisor[] = []
  dispatchers: Dispatcher[] = []
  remainingDisp: Dispatcher[] = []
  drivers: Driver[] = []
  remainingDrivers: Driver[] = []

  allDispatcherBlocks: DispatcherBlock[] = []
  allDriverBlocks: DriverBlock[] = []

  dispatcherBlocks: DispatcherBlock[] = []
  driverBlocks: DriverBlock[] = []

  supervisorsChecked = false
  dispatchersChecked = false
  driversChecked = false

  searchSupervisorKey = ''
  searchDispatcherKey = ''
  searchDriverKey = ''

  dispatchersDisplay: 'list' | 'blocks' | 'both' = 'list'
  driversDisplay: 'list' | 'blocks' | 'both' = 'list'

  dispatchersCount = 0
  driversCount = 0

  editTooltipPos: ConnectedPosition[] = [
    {
      originX: 'end',      // End (right) of the host element
      overlayX: 'end',   // Start (left) of the overlay
      originY: 'center',   // Bottom of the host element
      overlayY: 'top',     // Top of the overlay
      offsetX: 10,
      offsetY: 6
    }
  ]

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.loaderService.show();

    const drivers = this.usersService.getDrivers()
    const dispatchers = this.usersService.getDispatchers()
    const supervisors = this.usersService.getSupervisors()

    forkJoin([drivers, dispatchers, supervisors]).subscribe({
      next: ([drivers, dispatchers, supervisors]) => {
        this.allSupervisors = supervisors;
        this.allDispatchers = dispatchers;
        this.allDrivers = drivers;

        this.allSupervisors = this.allSupervisors.map(el => { return { ...el, selected: false, checked: false } })
        this.allDispatchers = this.allDispatchers.map(el => { return { ...el, selected: false, checked: false } })
        this.allDrivers = this.allDrivers.map(el => { return { ...el, selected: false, checked: false } })

        this.supervisors = structuredClone(this.allSupervisors)
        this.dispatchers = structuredClone(this.allDispatchers)
        this.drivers = structuredClone(this.allDrivers)

        this.dispatcherBlocks = this.getDispatcherBlocks(structuredClone(this.allSupervisors), structuredClone(this.allDispatchers))
        this.allDispatcherBlocks = structuredClone(this.dispatcherBlocks)

        this.driverBlocks = this.getDriverBlocks(structuredClone(this.allDispatchers), structuredClone(this.allDrivers))
        this.allDriverBlocks = structuredClone(this.driverBlocks)

        this.dispatchersCount = this.getDispatchersCount()
        this.driversCount = this.getDriversCount()
      },
      error: (error) => {
        this.loaderService.hide();
        console.error(error);
      },
      complete: () => {
        this.loaderService.hide();
      }
    });
  }

  checkSupervisors(): void {
    this.supervisorsChecked = !this.supervisorsChecked
    if (this.supervisorsChecked) {
      this.supervisors.forEach(el => el.checked = true)
    } else {
      this.supervisors.forEach(el => el.checked = false)
    }
  }

  checkSupervisor(supervisor: any): void {
    supervisor.checked = !supervisor.checked
    this.supervisorsChecked = this.supervisors.every(s => s.checked)
  }

  checkDispatchers(): void {
    this.dispatchersChecked = !this.dispatchersChecked
    if (this.dispatchersDisplay === 'list') {
      if (this.dispatchersChecked) {
        this.dispatchers.forEach(el => el.checked = true)
      } else {
        this.dispatchers.forEach(el => el.checked = false)
      }
    } else if (this.dispatchersDisplay === 'blocks') {
      if (this.dispatchersChecked) {
        this.dispatcherBlocks.forEach(block => block.array.forEach((el: any) => el.checked = true))
      } else {
        this.dispatcherBlocks.forEach(block => block.array.forEach((el: any) => el.checked = false))
      }
    }
  }

  checkDispatcher(dispatcher: any): void {
    dispatcher.checked = !dispatcher.checked
    if (this.dispatchersDisplay === 'list') {
      this.dispatchersChecked = this.dispatchers.every(s => s.checked)
    } else if (this.dispatchersDisplay === 'blocks') {
      const dispatchers = this.dispatcherBlocks.flatMap(block => block.array)
      this.dispatchersChecked = dispatchers.every(s => s.checked)
    }
  }

  checkDrivers(): void {
    this.driversChecked = !this.driversChecked
    if (this.driversDisplay === 'list') {
      this.drivers.forEach(d => d.checked = this.driversChecked)
    } else if (this.driversDisplay === 'blocks') {
      this.driverBlocks.flatMap(block => block.array).forEach(d => d.checked = this.driversChecked)
    } else if (this.driversDisplay === 'both') {
      this.driverBlocks.flatMap(block => block.array).forEach(d => d.checked = this.driversChecked)
      this.drivers.forEach(d => d.checked = this.driversChecked)
    }

  }

  checkDriver(driver: any): void {
    driver.checked = !driver.checked
    if (this.driversDisplay === 'list') {
      this.driversChecked = this.drivers.every(d => d.checked)
    } else if (this.driversDisplay === 'blocks') {
      const drivers = this.driverBlocks.flatMap(block => block.array)
      this.driversChecked = drivers.every(d => d.checked)
    } else if (this.driversDisplay === 'both') {
      const fromBlocks = this.driverBlocks.flatMap(block => block.array)
      this.driversChecked = this.drivers.concat(fromBlocks).every(d => d.checked)
    }
  }

  // selectSupervisor(supervisor: any): void {
  //   supervisor.selected = !supervisor.selected

  //   const selectedSupervisors = this.supervisors.filter(supervisor => supervisor.selected)



  //   if (selectedSupervisors.length > 1) {

  //     this.dispatchersDisplay = 'blocks'
  //     this.dispatcherBlocks = this.getDispatcherBlocks(selectedSupervisors, structuredClone(this.allDispatchers))
  //     this.dispatcherBlocks.forEach(block => block.array.forEach(dispatcher => dispatcher.selected = true))
  //     this.driversDisplay = 'both'
  //     const dispatchers = this.dispatcherBlocks.flatMap(block => block.array)      
  //     this.driverBlocks = this.getDriverBlocks(dispatchers, structuredClone(this.allDrivers))
  //     this.driverBlocks.forEach(block => block.array.forEach(driver => driver.selected = true))
  //   } else if (selectedSupervisors.length === 1) {
  //     this.dispatchersDisplay = 'list'
  //     this.dispatchers = this.allDispatchers.filter(dispatcher => selectedSupervisors.some(supervisor => dispatcher.supervisorId === supervisor.id))
  //     this.dispatchers.forEach(dispatcher => dispatcher.selected = true)
  //     if (this.dispatchers.length > 1) {
  //       this.driversDisplay = 'both'
  //       this.driverBlocks = this.getDriverBlocks(this.dispatchers, structuredClone(this.allDrivers))
  //       this.driverBlocks.forEach(block => block.array.forEach(driver => driver.selected = true))
  //     } else {
  //       this.drivers = this.allDrivers.filter(driver => this.dispatchers.some(dispatcher => dispatcher.id === driver.dispatcherId))
  //       this.drivers.forEach(driver => driver.selected = true)
  //     }
  //   } else {
  //     this.supervisors = structuredClone(this.allSupervisors)
  //     this.supervisors.forEach(supervisor => { supervisor.selected = false; supervisor.checked = false })
  //     this.dispatchersDisplay = 'list'
  //     this.dispatchers = structuredClone(this.allDispatchers)
  //     this.dispatchers.forEach(dispatcher => { dispatcher.selected = false; dispatcher.checked = false })
  //     this.driversDisplay = 'list'
  //     this.drivers = structuredClone(this.allDrivers)
  //     this.drivers.forEach(driver => { driver.selected = false; driver.checked = false })
  //   }

  //   this.driversCount = this.getDriversCount()
  //   this.dispatchersCount = this.getDispatchersCount()
  // }

  selectDriver(driver: any) {
    driver.selected = !driver.selected

    let selectedDrivers: any[] = []
    if (this.driversDisplay === 'list') {
      selectedDrivers = this.drivers.filter(driver => driver.selected)

    } else if (this.driversDisplay === 'both') {

      const a = structuredClone(this.driverBlocks);
      const blockedDriverIds = a.flatMap(block => block.array).map(driver => driver.id);
      let InBlockDisp: any = this.allDrivers.filter(driver => blockedDriverIds.includes(driver.id));
      InBlockDisp.find((f: any) => f.id !== driver.id) ?
        selectedDrivers = [...InBlockDisp, driver] :
        selectedDrivers = [...InBlockDisp];
    }

    if (selectedDrivers.length > 1) {
      this.driversDisplay = 'both'
      this.dispatchersDisplay = 'both'
      const selectedDispatchers = this.allDispatchers.filter(dispatcher => selectedDrivers.some(driver => driver.dispatcherId === dispatcher.id))

      this.dispatcherBlocks = this.getDispatcherBlocks(structuredClone(this.allSupervisors), selectedDispatchers);
      this.selectSuperVisorsByDrivers(selectedDrivers, selectedDispatchers);
      this.driverBlocks = this.getDriverBlocks(selectedDispatchers, selectedDrivers, this.supervisors.filter(supervisor => supervisor.selected))
      this.driverBlocks.forEach(block => block.array.forEach(driver => driver.selected = true))

      this.getRemainingDisp();
      this.getRemainingDriver();
    } else if (selectedDrivers.length === 1) {
      this.dispatchersDisplay = 'list'
      this.driversDisplay = 'list';
      const filtDisp: Array<Dispatcher> = this.allDispatchers.filter(dispatcher => selectedDrivers[0].dispatcherId === dispatcher.id)

      if (filtDisp.length > 0) {
        this.dispatchers.forEach(d => { if (d.id === filtDisp[0].id) d.selected = true; });
        this.selectSuperVisorsByDrivers(selectedDrivers, filtDisp);
      } else {
        this.dispatchers.length = 0;
        this.selectSuperVisorsByDrivers(selectedDrivers);
      }

    } else {
      this.dispatchersDisplay = 'list'
      this.driversDisplay = 'list'
      this.dispatchers = structuredClone(this.allDispatchers)
      this.dispatchers.forEach(dispatcher => dispatcher.selected = false)
      this.supervisors = structuredClone(this.allSupervisors)
      this.supervisors.forEach(supervisor => supervisor.selected = false)
      this.drivers = structuredClone(this.allDrivers)
      this.drivers.forEach(driver => driver.selected = false)
    }

    this.driversCount = this.getDriversCount()
    this.dispatchersCount = this.getDispatchersCount()
  }

  getRemainingDriver() {
    const blockedDriverIds = this.driverBlocks.flatMap(block => block.array).map(driver => driver.id);
    this.remainingDrivers = this.allDrivers.filter(driver => !blockedDriverIds.includes(driver.id));
    this.remainingDrivers.forEach(f => f.selected = false);
  }

  //-------------------------------------------------------------------------Supervisor-------------------------------------------------------------------------//
  selectSupervisor(supervisor: any): void {
    supervisor.selected = !supervisor.selected;

    const selectedSupervisors$ = from(this.supervisors).pipe(
      filter(sup => sup.selected),
      toArray()
    );

    selectedSupervisors$
      .pipe(
        switchMap(selectedSupervisors => {
          if (selectedSupervisors.length > 1) {

            // Handle multiple supervisors selection: switch to 'blocks' view
            this.dispatchersDisplay = 'both';
            return this.buildDispatcherBlocks(selectedSupervisors).pipe(
              switchMap(() => this.buildDriverBlocksForDispatchers(selectedSupervisors))
            );
          } else if (selectedSupervisors.length === 1) {
            // Handle single supervisor selection: switch to 'list' view
            this.dispatchersDisplay = 'list';
            return this.handleSingleSupervisorSelection(selectedSupervisors[0]);
          } else {
            // Reset all selections when no supervisor is selected
            this.resetSelectionsFromSuper();
            return of([]);
          }
        })
      )
      .subscribe(() => {
        // Update driver and dispatcher counts after operations complete
        this.driversCount = this.getDriversCount();
        this.dispatchersCount = this.getDispatchersCount();
        if (!this.driversCount && !this.dispatchersCount) {
          this.driversDisplay = 'list';
          this.drivers.length = 0;
        };

      });
  }

  // Helper: Build dispatcher blocks for multiple supervisors
  private buildDispatcherBlocks(selectedSupervisors: Supervisor[]) {
    return of(structuredClone(this.allDispatchers)).pipe(
      map(dispatchers => this.getDispatcherBlocks(selectedSupervisors, dispatchers)),
      tap(blocks => {
        this.dispatcherBlocks = blocks;
        blocks.forEach(block =>
          block.array.forEach(dispatcher => (dispatcher.selected = true))
        );
        let blockedDispatchers = this.dispatcherBlocks.flatMap(block => block.array).filter(f => f.selected)
        this.remainingDisp = this.allDispatchers.filter(f => !blockedDispatchers.some(s => s.id === f.id));
        this.remainingDisp.forEach(d => d.selected = false);
      })
    );
  }

  // Helper: Build driver blocks for selected dispatchers
  private buildDriverBlocksForDispatchers(supervisor: Array<Supervisor> = new Array<Supervisor>()) {
    let dispatchers: Array<Dispatcher> = new Array<Dispatcher>();
    if (this.dispatchersDisplay === 'list') dispatchers = this.dispatchers.filter(f => f.selected);
    if (this.dispatchersDisplay === 'both') dispatchers = this.dispatcherBlocks.flatMap(block => block.array);
    return of(structuredClone(this.allDrivers)).pipe(
      map(drivers => this.getDriverBlocks(dispatchers, drivers, supervisor)),
      tap(blocks => {
        this.driverBlocks = blocks;
        blocks.forEach(block =>
          block.array.forEach(driver => (driver.selected = true))
        );
        this.getRemainingDriver();
      })
    );
  }

  // Helper: Handle selection for a single supervisor
  private handleSingleSupervisorSelection(supervisor: any) {
    const dispatchers = this.allDispatchers.filter(
      dispatcher => dispatcher.supervisorId === supervisor.id
    );
    dispatchers.forEach(dispatcher => (dispatcher.selected = true));
    this.dispatchers = dispatchers;

    const driversBlockBySuper = (supervisor: Supervisor) => {
      this.driversDisplay = 'both';
      this.driverBlocks = this.getDriverBlocks(dispatchers, structuredClone(this.allDrivers), [supervisor]);
      return of(
        this.driverBlocks.forEach(block =>
          block.array.forEach(driver => (driver.selected = true))
        )
      );
    }
    switch (dispatchers.length) {
      case 0:
        return driversBlockBySuper(supervisor);
      case 1:
        const filteredDriversBySuper: Array<Driver> = this.allDrivers.filter(driver => driver.dispatcherId === supervisor.id);
        if (filteredDriversBySuper.length > 0) return driversBlockBySuper(supervisor);
        else {
          this.drivers = this.allDrivers.filter(driver => dispatchers.some(dispatcher => dispatcher.id === driver.dispatcherId));
          return of(this.drivers.forEach(driver => (driver.selected = true)));
        }
      default:
        this.driversDisplay = 'both';
        this.dispatchersDisplay = 'both';
        this.dispatcherBlocks = this.getDispatcherBlocks(structuredClone(this.allSupervisors), structuredClone(dispatchers));
        this.dispatcherBlocks.forEach(block => block.array.forEach(driver => driver.selected = true));
        this.dispatchers.forEach(f => f.selected = false);
        this.getRemainingDisp();
        return this.buildDriverBlocksForDispatchers([supervisor]);
    }
  }

  // Reset all selections to default state
  private resetSelectionsFromSuper() {
    this.supervisors = structuredClone(this.allSupervisors).map(supervisor => ({
      ...supervisor,
      selected: false,
      checked: false,
    }));
    this.dispatchersDisplay = 'list';
    this.dispatchers = structuredClone(this.allDispatchers).map(dispatcher => ({
      ...dispatcher,
      selected: false,
      checked: false,
    }));
    this.driversDisplay = 'list';
    this.drivers = structuredClone(this.allDrivers).map(driver => ({
      ...driver,
      selected: false,
      checked: false,
    }));
  }
  //-----------------------------------------------------------------------Supervisor~End-----------------------------------------------------------------------//


  //-------------------------------------------------------------------------Dispatcher-------------------------------------------------------------------------//
  // selectDispatcher(dispatcher: any): void {
  //   dispatcher.selected = !dispatcher.selected;

  //   console.log(this.dispatchers);

  //   let selectedDispatchers$ = of(this.dispatchers);

  //   // Get selected dispatchers by display
  //   if (this.dispatchersDisplay === 'blocks' || this.dispatchersDisplay === 'both') {
  //     console.log('both');

  //     selectedDispatchers$ = from(this.dispatcherBlocks).pipe(
  //       map(block => block.array),
  //       switchMap(array => from(array)),
  //       filter(d => d.selected),
  //       toArray()
  //     );
  //   } else {
  //     console.log('list');
  //     selectedDispatchers$ = selectedDispatchers$.pipe(
  //       map(dispatchers => dispatchers.filter(d => d.selected))
  //     );
  //   }

  //   // Process according to the number of dispatchers selected
  //   selectedDispatchers$.subscribe(selectedDispatchers => {
  //     this.dispatchersCount = selectedDispatchers.length;
  //     if (selectedDispatchers.length !== 1) {
  //       this.driversDisplay = 'both';
  //       this.dispatchersDisplay = 'both';
  //       this.driverBlocks = this.getDriverBlocks(selectedDispatchers, structuredClone(this.allDrivers));
  //       this.driverBlocks.forEach(block =>
  //         block.array.forEach(driver => (driver.selected = true))
  //       );

  //       // Update the status of supervisors reactively
  //       this.supervisors = this.allSupervisors.filter(supervisor =>selectedDispatchers.some(d => d.supervisorId === supervisor.id));
  //       this.supervisors.forEach(s=>s.selected = true);

  //       this.dispatcherBlocks = this.getDispatcherBlocks(structuredClone(this.allSupervisors), selectedDispatchers);
  //       const blockedDispatcherIds = this.dispatcherBlocks.flatMap(block => block.array).map(dispatcher => dispatcher.id);
  //       this.dispatchers = this.allDispatchers.filter(dispatcher => !blockedDispatcherIds.includes(dispatcher.id));
  //     } else if (selectedDispatchers.length === 1) {
  //       this.dispatchersDisplay = 'list';
  //       this.driversDisplay = 'list';

  //       this.dispatchers.forEach(d =>
  //         d.id === selectedDispatchers[0].id ? (d.selected = true) : (d.selected = false)
  //       );

  //       this.drivers = this.allDrivers.filter(driver => driver.dispatcherId === selectedDispatchers[0].id);
  //       this.drivers.forEach(driver => (driver.selected = true));

  //       this.supervisors.forEach(supervisor =>
  //         supervisor.selected = supervisor.id === selectedDispatchers[0].supervisorId
  //       );
  //       this.supervisors = this.supervisors.filter(s=>s.selected);
  //     } else {
  //       // Reset all lists if no dispatchers are selected
  //       this.resetSelectionsFromDisp();
  //     }

  //     // Update counters
  //     this.driversCount = this.getDriversCount();
  //     this.dispatchersCount = this.getDispatchersCount();
  //   });
  // }

  deselectDispatcher(dispatcher: any): void {
    this.dispatcherBlocks = this.deleteObjectFromArray(this.dispatcherBlocks, dispatcher).filter(f => f.array.length > 0);
    if (this.dispatcherBlocks.flatMap(block => block.array).length === 0) {
      this.dispatchers = structuredClone(this.allDispatchers);
      this.dispatchersDisplay = 'list';
      this.driversDisplay = 'list';
      this.clearAll();
    } else {
      this.remainingDisp.push(dispatcher);
      this.remainingDisp.forEach(d => d.selected = false);
      let remainingDispFromBlocks = this.dispatcherBlocks.flatMap(block => block.array).filter(f => f.id !== dispatcher.id);
      this.driverBlocks = this.getDriverBlocks(remainingDispFromBlocks, structuredClone(this.allDrivers));
      this.driverBlocks.forEach(block => block.array.forEach(driver => driver.selected = true));
      this.supervisors.forEach(supervisor => supervisor.selected = remainingDispFromBlocks.some(dispatcher => dispatcher.supervisorId === supervisor.id));
      this.getRemainingDisp();
      this.getRemainingDriver();
    }
  }

  deSelectDriver(driver: any) {
    this.driverBlocks = this.deleteObjectFromArray(this.driverBlocks, driver).filter(f => f.array.length > 0);

    if (this.driverBlocks.flatMap(block => block.array).length === 0) {
      this.dispatchers = structuredClone(this.allDispatchers);
      this.dispatchersDisplay = 'list';
      this.driversDisplay = 'list';
      this.clearAll();
    } else {
      this.remainingDrivers.push(driver);
      this.remainingDrivers.forEach(d => d.selected = false);
      let remainingDriversFromBlocks = this.driverBlocks.flatMap(block => block.array).filter(f => f.id !== driver.id);
      let selectedDispatchers: Array<Dispatcher> = new Array<Dispatcher>();
      if (this.dispatchersDisplay === 'list') selectedDispatchers = this.dispatchers.filter(f => f.selected);
      if (this.dispatchersDisplay === 'both') selectedDispatchers = this.allDispatchers.filter(dispatcher => remainingDriversFromBlocks.some(driver => driver.dispatcherId === dispatcher.id));
      this.dispatcherBlocks = this.getDispatcherBlocks(structuredClone(this.allSupervisors), selectedDispatchers);
      this.dispatcherBlocks.forEach(block => block.array.forEach(driver => driver.selected = true));
      const blockedDrivers = this.driverBlocks.flatMap(block => block.array).filter(f => f.selected);
      this.supervisors.forEach(element => {
        element.selected = false;
        if (selectedDispatchers.some(s => s.supervisorId === element.id)) element.selected = true;
        if (blockedDrivers.some(s => s.dispatcherId === element.id)) element.selected = true;
      });
      this.getRemainingDisp();
      this.getRemainingDriver();
    }
  }

  deleteObjectFromArray(arr: any[], objToRemove: any): any[] {
    arr.forEach(item => item.array = item.array.filter((item: any) => item.id !== objToRemove.id));
    return arr;
  }

  selectDispatcher(dispatcher: any): void {
    dispatcher.selected = !dispatcher.selected

    let selectedDispatchers: any[] = []
    if (this.dispatchersDisplay === 'list') {
      selectedDispatchers = this.dispatchers.filter(dispatcher => dispatcher.selected)

    } else if (this.dispatchersDisplay === 'both') {
      const a = structuredClone(this.dispatcherBlocks);
      const blockedDispatcherIds = a.flatMap(block => block.array).map(dispatcher => dispatcher.id);
      let InBlockDisp: any = this.allDispatchers.filter(dispatcher => blockedDispatcherIds.includes(dispatcher.id));
      InBlockDisp.find((f: any) => f.id !== dispatcher.id) ?
        selectedDispatchers = [...InBlockDisp, dispatcher] :
        selectedDispatchers = [...InBlockDisp];
    }
    this.dispatchersCount = selectedDispatchers.length

    if (selectedDispatchers.length > 1) {
      this.driversDisplay = 'both'
      this.dispatchersDisplay = 'both'
      this.driverBlocks = this.getDriverBlocks(selectedDispatchers, structuredClone(this.allDrivers))
      this.driverBlocks.forEach(block => block.array.forEach(driver => driver.selected = true))
      this.supervisors.forEach(supervisor => supervisor.selected = selectedDispatchers.some(dispatcher => dispatcher.supervisorId === supervisor.id))
      this.dispatcherBlocks = this.getDispatcherBlocks(structuredClone(this.allSupervisors), selectedDispatchers);
      this.getRemainingDisp();
      this.getRemainingDriver();
    } else if (selectedDispatchers.length === 1) {
      this.dispatchersDisplay = 'list'
      this.driversDisplay = 'list'
      this.dispatchers.forEach(dispatcher => dispatcher.id === selectedDispatchers[0].id ? dispatcher.selected = true : {})
      this.drivers = this.allDrivers.filter(driver => driver.dispatcherId === selectedDispatchers[0].id)
      this.drivers.forEach(driver => driver.selected = true)
      this.supervisors.forEach(supervisor => {
        if (supervisor.id === selectedDispatchers[0].supervisorId) {
          supervisor.selected = true
        } else {
          supervisor.selected = false
        }
      })
    } else {
      this.driversDisplay = 'list'
      this.drivers = structuredClone(this.allDrivers)
      this.supervisors.forEach(supervisor => supervisor.selected = false)
      this.dispatchers = structuredClone(this.allDispatchers)
      this.dispatchers.forEach(dispatcher => dispatcher.selected = false)
      this.dispatchersDisplay = 'list'
      this.drivers.forEach(driver => driver.selected = false)
    }
    this.driversCount = this.getDriversCount()
    this.dispatchersCount = this.getDispatchersCount()
  }

  private resetSelectionsFromDisp() {
    this.driversDisplay = 'list';
    this.drivers = structuredClone(this.allDrivers);
    this.dispatchers = structuredClone(this.allDispatchers);
    this.supervisors = structuredClone(this.allSupervisors);
    this.supervisors.forEach(supervisor => (supervisor.selected = false));
    this.dispatchers.forEach(dispatcher => (dispatcher.selected = false));
    this.drivers.forEach(driver => (driver.selected = false));
  }

  getRemainingDisp() {
    const blockedDispatcherIds = this.dispatcherBlocks.flatMap(block => block.array).map(dispatcher => dispatcher.id);
    this.remainingDisp = this.allDispatchers.filter(dispatcher => !blockedDispatcherIds.includes(dispatcher.id));
    this.remainingDisp.forEach(f => f.selected = false);
  }
  //---------------------------------------------------------------------Dispatcher~End---------------------------------------------------------------------//

  //-------------------------------------------------------------------------Driver-------------------------------------------------------------------------//
  // selectDriver(driver: any): void {
  //   driver.selected = !driver.selected;

  //   let selectedDrivers$ = this.getSelectedDrivers();

  //   selectedDrivers$.subscribe(selectedDrivers => {
  //     if (selectedDrivers.length > 1) {
  //       this.handleMultipleDrivers(selectedDrivers);
  //     } else if (selectedDrivers.length === 1) {
  //       this.handleSingleDriver(selectedDrivers[0]);
  //     } else {
  //       this.resetSelectionsFromDriver();
  //     }

  //     this.driversCount = this.getDriversCount();
  //     this.dispatchersCount = this.getDispatchersCount();
  //   });
  // }

  // Utility function to get selected drivers based on the current display mode.
  getSelectedDrivers() {
    if (this.driversDisplay === 'list') {
      return of(this.drivers.filter(driver => driver.selected));
    } else if (this.driversDisplay === 'blocks') {
      return of(this.driverBlocks.flatMap(block => block.array).filter(driver => driver.selected));
    } else if (this.driversDisplay === 'both') {
      const selectedFromList = this.drivers.filter(driver => driver.selected);
      const selectedFromBlocks = this.driverBlocks.flatMap(block => block.array).filter(driver => driver.selected);

      this.drivers.forEach(driver => (driver.selected = false));
      return of([...selectedFromList, ...selectedFromBlocks]);
    }
    return of([]);
  }

  // Handle logic when multiple drivers are selected.
  handleMultipleDrivers(selectedDrivers: any[]) {
    const selectedDispatchers$ = this.getSelectedDispatchers(selectedDrivers);

    selectedDispatchers$.pipe(
      tap(dispatchers => {
        this.dispatchersDisplay = 'both';
        this.dispatcherBlocks = this.getDispatcherBlocks(structuredClone(this.allSupervisors), dispatchers);
        this.markSelectedInBlocks(this.dispatcherBlocks, true);

        this.supervisors = this.allSupervisors.filter(supervisor =>
          this.dispatcherBlocks.some(block => block.supervisorId === supervisor.id)
        );
        this.supervisors.forEach(supervisor => (supervisor.selected = true));
      }),
      switchMap(() => this.getSelectedDispatchersSum())
    ).subscribe(selectedDispatchersSum => {
      if (selectedDrivers.length > 1) {
        this.driversDisplay = 'both';
        this.refreshDriversBlocks(selectedDrivers);
      }
      const selDisp: Array<Dispatcher> = this.allDispatchers.filter(dispatcher => selectedDrivers.some(driver => driver.dispatcherId === dispatcher.id));
      this.selectSuperVisorsByDrivers(selectedDrivers, selDisp);
    });
  }

  // Fetch selected dispatchers based on drivers.
  getSelectedDispatchers(selectedDrivers: any[]) {
    return of(
      this.allDispatchers.filter(dispatcher =>
        selectedDrivers.some(driver => driver.dispatcherId === dispatcher.id)
      )
    );
  }

  // Get sum of selected dispatchers.
  getSelectedDispatchersSum() {
    const dispatchers = this.dispatcherBlocks.flatMap(block => block.array);
    return of(dispatchers.filter(dispatcher => dispatcher.selected).length);
  }

  // Handle logic for a single selected driver.
  handleSingleDriver(selectedDriver: any) {
    this.dispatchersDisplay = 'list';
    this.dispatchers = this.allDispatchers.filter(dispatcher => selectedDriver.dispatcherId === dispatcher.id);

    if (this.dispatchers.length) {
      this.dispatchers[0].selected = true;
      this.supervisors = this.allSupervisors.filter(supervisor => supervisor.id === this.dispatchers[0].supervisorId);
      if (this.supervisors.length) this.supervisors[0].selected = true;
    } else {
      this.selectSuperVisorsByDrivers([selectedDriver]);
    }

    if (this.driversDisplay === 'both') {
      this.driverBlocks = this.getDriverBlocks(this.dispatchers, structuredClone(this.allDrivers));
      this.markSelectedInBlocks(this.driverBlocks, driver =>
        [selectedDriver].some(selected => selected.DriverId === driver.id)
      );
      this.drivers = this.allDrivers.filter(driver =>
        !this.dispatchers.some(dispatcher => dispatcher.id === driver.dispatcherId)
      );
    }
  }

  // Reset all selections to their default state.
  private resetSelectionsFromDriver(): void {
    this.driversDisplay = 'list';
    this.dispatchersDisplay = 'list';

    this.supervisors = structuredClone(this.allSupervisors);
    this.dispatchers = structuredClone(this.allDispatchers);
    this.drivers = structuredClone(this.allDrivers);

    this.supervisors.forEach(supervisor => (supervisor.selected = false));
    this.dispatchers.forEach(dispatcher => (dispatcher.selected = false));
    this.drivers.forEach(driver => (driver.selected = false));

    this.driversCount = this.getDriversCount();
    this.dispatchersCount = this.getDispatchersCount();
  }

  // Utility function to reset selection state for an array of items.
  resetArraySelection(array: any[]) {
    array.forEach(item => (item.selected = false));
  }

  // Mark items in blocks as selected or not.
  markSelectedInBlocks(blocks: any[], isSelected: boolean | ((item: any) => boolean)) {
    blocks.forEach(block =>
      block.array.forEach((item: any) => {
        item.selected = typeof isSelected === 'function' ? isSelected(item) : isSelected;
      })
    );
  }

  // Refresh driver blocks when multiple drivers and dispatchers are selected.
  refreshDriversBlocks(selectedDrivers: any[]) {
    const dispatchers = this.dispatcherBlocks.flatMap(block => block.array);
    const selectedDispatchers = dispatchers.filter(dispatcher => dispatcher.selected);

    this.driverBlocks = this.getDriverBlocks(selectedDispatchers, structuredClone(this.allDrivers));
    this.markSelectedInBlocks(this.driverBlocks, driver =>
      selectedDrivers.some(selected => selected.DriverId === driver.id)
    );

    this.drivers = this.allDrivers.filter(driver =>
      !selectedDispatchers.some(dispatcher => dispatcher.id === driver.dispatcherId)
    );
  }

  selectSuperVisorsByDrivers(selectedDrivers: Array<Driver>, selectedDispatchers?: Array<Dispatcher>) {
    const filteredSuperByDrivers: Array<Supervisor> = this.allSupervisors.filter(supervisor => selectedDrivers.some(driver => driver.dispatcherId === supervisor.id));
    if (filteredSuperByDrivers.length !== 0) {
      for (const supervisor of this.supervisors) {
        for (const filteredSupervisor of filteredSuperByDrivers) {
          if (filteredSupervisor.id === supervisor.id) supervisor.selected = true;
        }
      }
    }
    if (selectedDispatchers?.length && selectedDispatchers.length > 0) {
      const filteredSuperByDisp: Array<Supervisor> = this.supervisors.filter(f => selectedDispatchers.some(s => s.supervisorId === f.id));
      if (filteredSuperByDisp.length !== 0) {
        for (const supervisor of this.supervisors) {
          for (const filteredSupervisor of filteredSuperByDisp) {
            if (filteredSupervisor.id === supervisor.id) supervisor.selected = true;
          }
        }
      }
    }
  }
  //-------------------------------------------------------------------------Driver~End-------------------------------------------------------------------------//
  isSuperSearchDisabled(): boolean {
    return this.supervisors.filter(f => f.selected).length > 0;
  }

  isDispatcherSearchDisabled(): boolean {
    if (this.dispatchersDisplay === 'list') return this.dispatchers.filter(f => f.selected).length > 0 || this.dispatchers.length === 0;
    else return this.dispatcherBlocks.flatMap(block => block.array).filter(f => f.selected).length > 0 || this.dispatcherBlocks.flatMap(block => block.array).length === 0;
  }

  isDriversSearchDisabled(): boolean {
    if (this.driversDisplay === 'list') return this.drivers.filter(f => f.selected).length > 0 || this.drivers.length === 0;
    else return this.driverBlocks.flatMap(block => block.array).filter(f => f.selected).length > 0 || this.driverBlocks.flatMap(block => block.array).length === 0;

  }

  filterSupervisorsByKey(): void {
    this.supervisors = this.allSupervisors.filter(el => el.fullName.toLowerCase().includes(this.searchSupervisorKey.toLowerCase()))
  }

  filterDispatchersByKey(): void {
    if (this.dispatchersDisplay === 'list') {
      this.dispatchers = this.allDispatchers.filter(el => el.fullName.toLowerCase().includes(this.searchDispatcherKey.toLowerCase()))
      this.dispatchers.forEach(dispatcher => dispatcher.selected = false)
    } else if (this.dispatchersDisplay === 'blocks') {

      // const allDispatcherBlocks = structuredClone(this.allDispatcherBlocks)
      // console.log(allDispatcherBlocks);

      this.dispatcherBlocks.filter(block => {
        block.array = block.array.filter((el: any) => {
          return el.fullName.toLowerCase().includes(this.searchDispatcherKey.toLowerCase())
        })

        // if (block.array.length > 0) {
        //   return true
        // }
        // return false
      })
      this.dispatcherBlocks = this.dispatcherBlocks.filter(f => f.array.length > 0);
      this.dispatcherBlocks.forEach(block => block.array.forEach(dispatcher => dispatcher.selected = true))
    }
  }

  filterDriversByKey(): void {
    if (this.driversDisplay === 'list') {
      this.drivers = this.allDrivers.filter(el => el.fullName.toLowerCase().includes(this.searchDriverKey.toLowerCase()))
      this.drivers.forEach(driver => driver.selected = false)
    } else if (this.driversDisplay === 'blocks') {
      if (this.dispatchersDisplay === 'list') {
        const selectedDispatchers = this.dispatchers.filter(dispatcher => dispatcher.selected)
        const selectedSupers = this.supervisors.filter(supervisor => supervisor.selected);
        let driverBlocks = this.getDriverBlocks(selectedDispatchers, structuredClone(this.allDrivers), selectedSupers)
        driverBlocks = driverBlocks.filter(block => {
          const array = block.array.filter(driver => driver.fullName.toLowerCase().includes(this.searchDriverKey.toLowerCase()))
          return array.length
        })
        driverBlocks.forEach(block => block.array = block.array.filter(driver => driver.fullName.toLowerCase().includes(this.searchDriverKey.toLowerCase())))
        driverBlocks.forEach(block => block.array.forEach(driver => driver.selected = true))
        this.driverBlocks = driverBlocks
      } else if (this.dispatchersDisplay === 'blocks') {
        const dispatchers = this.dispatcherBlocks.flatMap(block => block.array)
        const selectedDispatchers = dispatchers.filter(dispatcher => dispatcher.selected)
        const selectedSupers = this.supervisors.filter(supervisor => supervisor.selected);
        let driverBlocks = this.getDriverBlocks(selectedDispatchers, structuredClone(this.allDrivers), selectedSupers)
        driverBlocks = driverBlocks.filter(block => {
          const array = block.array.filter(driver => driver.fullName.toLowerCase().includes(this.searchDriverKey.toLowerCase()))
          return array.length
        })
        driverBlocks.forEach(block => block.array = block.array.filter(driver => driver.fullName.toLowerCase().includes(this.searchDriverKey.toLowerCase())))
        driverBlocks.forEach(block => block.array.forEach(driver => driver.selected = true))
        this.driverBlocks = driverBlocks
      }
    }
  }

  getDispatcherBlocks(supervisors: any[], dispatchers: any[]): DispatcherBlock[] {
    const blocks: DispatcherBlock[] = [];
    supervisors.forEach(supervisor => {

      if (!dispatchers.some(el => el.supervisorId === supervisor.id)) return

      blocks.push({
        supervisorName: supervisor.fullName,
        supervisorId: supervisor.id,
        array: []
      })
      dispatchers.forEach(dispatcher => {
        if (dispatcher.supervisorId === supervisor.id) {
          dispatcher.selected = true;
          const block = blocks.find(block => block.supervisorId === dispatcher.supervisorId)
          if (block) {
            block.array.push(dispatcher)
          }
        }
      })
    })
    return blocks
  }

  // getDriverBlocks(dispatchers: Dispatcher[], drivers: Driver[], supervisor?: Supervisor): DriverBlock[] {
  //   const blocks: DriverBlock[] = [];

  //   // Create blocks for dispatchers with matching drivers
  //   dispatchers.forEach(dispatcher => {
  //     const dispatcherDrivers = drivers.filter(driver => driver.dispatcherId === dispatcher.id);

  //     if (dispatcherDrivers.length) {
  //       blocks.push({
  //         supervisorName: dispatcher.supervisorName || '',
  //         supervisorId: dispatcher.supervisorId || 0,
  //         dispatcherName: dispatcher.fullName,
  //         dispatcherId: dispatcher.id,
  //         array: dispatcherDrivers
  //       });
  //     }
  //   });

  //   // Create a separate block for the supervisor if matching drivers exist
  //   if (supervisor) {
  //     const supervisorDrivers = drivers.filter(driver => driver.dispatcherId === supervisor.id);

  //     if (supervisorDrivers.length) {
  //       blocks.push({
  //         supervisorName: supervisor.fullName,
  //         supervisorId: supervisor.id,
  //         dispatcherName: '', // No dispatcher name for supervisor block
  //         dispatcherId: supervisor.id,
  //         array: supervisorDrivers
  //       });
  //     }
  //   }
  //   return blocks;
  // }

  getDriverBlocks(dispatchers: Dispatcher[], drivers: Driver[], supervisors: Array<Supervisor> = new Array<Supervisor>()): DriverBlock[] {
    const blocks: DriverBlock[] = [];

    // Iterate over each dispatcher to create dispatcher-specific blocks
    dispatchers.forEach(dispatcher => {
      if (drivers.some(driver => driver.dispatcherId === dispatcher.id)) {
        blocks.push({
          supervisorName: '',
          supervisorId: 0,
          dispatcherName: dispatcher.fullName,
          dispatcherId: dispatcher.id,
          array: []
        });

        drivers.forEach(driver => {
          if (driver.dispatcherId === dispatcher.id) {
            const block = blocks.find(block => block.dispatcherId === driver.dispatcherId);
            if (block) {
              block.array.push(driver);
            }
          }
        });

        // Update supervisor details for dispatcher blocks
        blocks.forEach(block => {
          dispatchers.forEach(dispatcher => {
            if (block.dispatcherId === dispatcher.id) {
              block.supervisorId = dispatcher.supervisorId;
              block.supervisorName = dispatcher.supervisorName;
            }
          });
        });
      }
    });

    if (supervisors) {
      // Create separate blocks for each supervisor in the supervisors array
      supervisors.forEach(supervisor => {
        const supervisorDrivers = drivers.filter(driver => driver.dispatcherId === supervisor.id);

        if (supervisorDrivers.length) {
          blocks.push({
            supervisorName: supervisor.fullName,
            supervisorId: supervisor.id,
            dispatcherName: '', // No dispatcher name for supervisor blocks
            dispatcherId: supervisor.id, // Set dispatcherId to supervisor's ID for consistency
            array: supervisorDrivers
          });
        }
      });
    }
    return blocks;
  }

  clearAll() {
    this.clearSupervisors();
    this.clearDispatchers();
    this.clearDrivers();
  }

  clearDrivers(): void {
    this.drivers = structuredClone(this.allDrivers)
    this.driversDisplay = 'list'
    this.drivers.forEach(driver => { driver.selected = false; driver.checked = false })
    this.driversChecked = false
    this.driversCount = this.getDriversCount()
    this.searchDriverKey = '';
  }

  clearDispatchers(): void {
    this.dispatchers = structuredClone(this.allDispatchers)
    this.dispatchersDisplay = 'list'
    this.dispatchers.forEach(dispatcher => { dispatcher.selected = false; dispatcher.checked = false })
    this.dispatchersChecked = false
    this.dispatchersCount = this.getDispatchersCount()
    this.searchDispatcherKey = '';
  }

  clearSupervisors(): void {
    this.supervisors = structuredClone(this.allSupervisors)
    this.supervisors.forEach(supervisor => { supervisor.selected = false; supervisor.checked = false })
    this.supervisorsChecked = false
    this.searchSupervisorKey = '';
  }

  addUserClick(role: Array<string>) {
    this.openAddUserPopup(role);
  }

  addDriver() {
    this.openCreateDriverPopup(true);
  }

  openAddUserPopup(role: Array<string>) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);

    const componentInstance = dialogRef.componentInstance;
    const data = { roles: role, createUser: true };
    componentInstance.subscribe({ data: data }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "ok":
          this.loaderService.show();
          this.addUser(popup.formData).then(() => {
            this.loaderService.hide();
            this.getData();
            componentInstance.close();
          });
          break;
      }
    });
  }

  openCreateDriverPopup(coDriverVisible: boolean = false) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);

    const componentInstance = dialogRef.componentInstance;
    const data = { roles: [UserRolesCode.DRIVER], tempCreateDriver: true, coDriverVisible: coDriverVisible, gSearchID: 'masterDriver' };
    componentInstance.subscribe({ data: data }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "addCoDriver": this.addCoDriverPopup(false, popup.formData, componentInstance); break;
        case "ok":
          this.loaderService.show();
          this.createDriver(popup.formData).then(() => {
            this.loaderService.hide();
            this.notificationService.success("Succes", 'Driver was added succesfully!');
            this.getData();
            componentInstance.close();
          }, err => {
            this.loaderService.hide();
          });
          break;
      }
    });
  }

  addCoDriverPopup(coDriverVisible: boolean, masterDriverData: any, masterPopupInstance: any, masterDriverID?: number) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;
    const data = { roles: [UserRolesCode.DRIVER], tempCreateDriver: true, coDriverVisible: coDriverVisible, masterDriverData: masterDriverData, gSearchID: 'coDriver' };
    componentInstance.subscribe({ data: data }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "ok":
          masterDriverData.coDriver = popup.formData;
          if (masterDriverID) {
            this.updateDriver(masterDriverData, masterDriverID).then(() => {
              this.notificationService.success("Succes", String.raw`Driver \ CoDriver was updated succesfully!`);
              this.loaderService.hide();
              componentInstance.close();
              masterPopupInstance.close();
              this.getData();
            }, err => {
              console.log(err);
              this.loaderService.hide();
            });
          } else {
            this.loaderService.show();
            this.createDriver(masterDriverData).then(() => {
              this.notificationService.success("Succes", String.raw`Driver \ CoDriver was added succesfully!`);
              this.loaderService.hide();
              componentInstance.close();
              masterPopupInstance.close();
              this.getData();
            }, err => {
              console.log(err);
              this.loaderService.hide();
            });
          }
          break;
      }
    });
  }

  async recycleBin() {
    let response: any;
    const dialogRef = this.dialog1.open(RecycleBinComponent);
    const componentInstance = dialogRef.componentInstance;
    const columns = [
      { field: 'userName', header: 'User Name' },
      { field: 'role', header: 'User Role' },
      { field: 'deletedBy', header: 'Who Deleted' },
      { field: 'deletionDate', header: 'Date & Time' },
    ];

    try {
      this.loaderService.show();
      response = await firstValueFrom(this.usersService.getDeletedUsers());
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
    }

    if (!response) return;

    const data = response.map((user: any) => ({
      ...user,
      deletionDate: moment(user.deletionDate, "YYYY-MM-DD HH:mm:ss.S").format("D MMMM YYYY, hh:mm:ss A")
    }));
    data.columns = columns;
    data.title = 'Deactivated Users';

    componentInstance.subscribe(data, (popup: any) => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "restore":
          this.loaderService.show();
          this.usersService.restoreUsers(popup.data).subscribe(data => {
            componentInstance.close();
            this.getData();
          }, (error) => {
            this.loaderService.hide();
            console.error(error);
          });
          break;
      }
    });
  }

  prepareReassign(): void {
    this.prepareDuplicate(true);
  }

  prepareDuplicate(reassign: boolean = false): void {
    let allCheckedDrivers: any[] = this.getCheckedDrivers()
    let allCheckedDispatchers: any[] = this.getCheckedDispatchers()
    let shareProps: any = {};

    switch (true) {
      //share only driver
      case allCheckedDrivers.length > 0 && allCheckedDispatchers.length === 0:
        shareProps = { allCheckedDrivers: allCheckedDrivers, allDispatchers: this.allDispatchers, allSupervisors: this.allSupervisors, reassign: reassign };
        this.openSharePopup(shareProps);
        break;

      //share only dispatcher
      case allCheckedDrivers.length === 0 && allCheckedDispatchers.length > 0:
        shareProps = { allCheckedDispatchers: allCheckedDispatchers, allSupervisors: this.allSupervisors, reassign: reassign };
        this.openSharePopup(shareProps);
        break;

      //share driver & dispatcher same time
      // case allCheckedDrivers.length > 0 && allCheckedDispatchers.length > 0:
      //     shareProps = {
      //       allCheckedDrivers: allCheckedDrivers,
      //       allCheckedDispatchers: allCheckedDispatchers, 
      //       allDispatchers: this.allDispatchers,
      //       allSupervisors: this.allSupervisors
      //     };
      //     this.openSharePopup(shareProps);
      // break;
    }
  }

  openSharePopup(props: any) {
    const dialogRef = this.dialog1.open(DuplicateToUsersComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe(props, (popup: any) => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "ok": this.shareHandler(popup, componentInstance); componentInstance.close(); break;
      }
    });
  }

  shareHandler(shared: any, componentInstance: any) {
    if (shared.reassign) {
      this.loaderService.show();
      this.reassignService.reassignConnection(shared.shareData).subscribe(() => {
        this.notificationService.success("Succes", 'Connection reassigned succesfully!');
        this.getData();
        componentInstance.close();
      }, err => {
        this.loaderService.hide();
      });
    } else this.openSelectDuplicateScopePopup(shared);
  }

  openSelectDuplicateScopePopup(shared: any) {
    const dialogRef = this.dialog1.open(SelectDuplicatedDriversScopeComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({}, (popup: any) => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "ok":
          this.loaderService.show();
          this.duplicateService.duplicateConnections(popup.scope, shared.shareData).subscribe(() => {
            this.notificationService.success("Succes", 'Connection created succesfully!');
            this.getData();
            componentInstance.close();
          }, err => {
            this.loaderService.hide();
          });
          break;
      }
    });
  }

  isShareDisabled(): boolean {
    return (
      (this.getCheckedDispatchers().length > 0 && this.getCheckedDrivers().length !== 0)
      || (this.getCheckedDispatchers().length !== 0 && this.getCheckedDrivers().length > 0)
      || (this.getCheckedDispatchers().length === 0 && this.getCheckedDrivers().length === 0));
  }

  isReassignDisabled(): boolean {
    return this.isShareDisabled();
    // return ( 
    //    (this.getCheckedDispatchers().length > 1 && this.getCheckedDrivers().length === 0) 
    // || (this.getCheckedDispatchers().length === 0 && this.getCheckedDrivers().length > 1) 
    // || (this.getCheckedDispatchers().length === 0 && this.getCheckedDrivers().length === 0)
    // || (this.getCheckedDispatchers().length > 0 && this.getCheckedDrivers().length > 0));
  }

  async editUserClick(userData: any, role?: Array<string>) {
    if (!userData.accessRights.isEditable) return;
    this.loaderService.show();
    let result: any;
    if (role?.join() === 'Driver') {
      result = await this.loadsService.getDriverById(userData.id);
      result.roles = role;
      result = { ...result, gSearchID: 'masterDriver', coDriverVisible: true };
      this.openEditMainDriverPopup(result);
    } else {
      result = await firstValueFrom(this.usersPageService.getUserByID(userData.id));
      this.openEditUserPopup(result);
    }
    this.loaderService.hide();
  }

  openEditMainDriverPopup(userData: any) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: userData }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + userData.firstName + ' ' + userData.lastName + ' ? This action cannot be undone.'
          this.deactivateHandler(text, userData, componentInstance);
          break;
        case "ok": this.driverConfirmHandler(popup.formData, userData.id, componentInstance); break;
        case "editCodriver":
          popup.formData.id = userData.id;
          this.editCoDriverPopup(popup.formData, userData.coDriverId, componentInstance);
          break;

        case "addCoDriver": this.addCoDriverPopup(false, popup.formData, componentInstance, userData.id); break;
      }
    });
  }

  driverConfirmHandler(masterDriverForm: any, driverID: number, masterPopupInstance: any, detailPopupInstance?: any) {
    this.updateDriver(masterDriverForm, driverID).then(() => {
      if (masterDriverForm.coDriver) this.notificationService.success("Succes", String.raw`Driver \ CoDriver was updated succesfully!`);
      else this.notificationService.success("Succes", `Driver was updated succesfully!`);
      masterPopupInstance.close();
      if (detailPopupInstance) detailPopupInstance.close();
      this.getData();
    });
  }

  deleteUserClick(userData: any, role?: Array<string>) {
    if (!userData.accessRights.isDeletable) return;
    userData.roles = role;
    const text: string = 'Are you sure you want to deactivate ' + userData.fullName + ' ? This action cannot be undone.';
    this.deactivateHandler(text, userData);
  }

  openEditUserPopup(userData: any) {
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: userData }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + userData.firstName + ' ' + userData.lastName + ' ? This action cannot be undone.'
          this.deactivateHandler(text, userData, componentInstance);
          break;
        case "ok":
          this.confirmHandler(popup, userData, componentInstance);
          break;
      }
    });
  }

  async editCoDriverPopup(mainDriver: any, codriverID: number, masterPopupInstance: any) {
    let coDriverData = await this.loadsService.getDriverById(codriverID);
    const masterDriverData = {
      trailerId: mainDriver.trailerId,
      vehicleId: mainDriver.vehicleId,
      dispatcherId: mainDriver.dispatcherId,
      carrierId: mainDriver.carrierId,
      newTrailerUnit: mainDriver.newTrailerUnit || null,
      newVehicleUnit: mainDriver.newTrailerUnit || null,
      newCarrierName: mainDriver.newTrailerUnit || null,
    }
    coDriverData = { ...coDriverData, gSearchID: 'coDriver', coDriverVisible: false, masterDriverData: masterDriverData, roles: [UserRolesCode.DRIVER] };
    const dialogRef = this.dialog1.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    let data: any = { data: coDriverData };

    componentInstance.subscribe(data, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.formData.firstName + ' ' + popup.formData.lastName + ' ? This action cannot be undone.'
          masterPopupInstance.driverFormComp.row.coDriverId = null;
          this.deactivateHandler(text, popup.formData).then(res => {
            if (res) {
              masterPopupInstance.driverFormComp.row.coDriverId = null;
              componentInstance.close();
            }
          });
          break;
        case "ok":
          mainDriver.coDriver = popup.formData;
          mainDriver.coDriver.id = codriverID;
          mainDriver.roles = [UserRolesCode.DRIVER];
          this.driverConfirmHandler(mainDriver, mainDriver.id, masterPopupInstance, componentInstance);
          break;
      }
    });
  }

  confirmHandler(popup: any, row: any, componentInstance: any) {
    this.updateUser(popup.formData, row.id).then((res) => {
      this.notificationService.success("Succes", `${res?.roles.join() || 'User '} was updated succesfully!`)
      componentInstance.close();
      this.getData();
    });
  }

  deactivateHandler(text: string, row: any, parentDialogRef?: any): Promise<boolean> {
    return new Promise<any>((resolve) => {
      this.openDeleteDialog(text).then(res => {
        if (!res) return;

        switch (row.roles[0]) {
          case 'Driver':
            this.loaderService.show();
            this.usersService.deleteDriver(row.id).subscribe(() => {
              resolve(true);
              if (parentDialogRef) parentDialogRef.close();
              this.getData();
              this.clearAll();
              this.loaderService.hide();
            }, error => {
              this.loaderService.hide();
              console.log(error);
            });
            break;

          default:
            this.loaderService.show();
            this.usersPageService.deleteUser(row.id).subscribe(() => {
              if (parentDialogRef) parentDialogRef.close();
              this.getData();
              this.loaderService.hide();
            }, error => {
              this.loaderService.hide();
              console.log(error);
            });
            break;
        }
      });
    });
  }

  updateDriver(data: Array<any>, driverID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.updateDriver(data, driverID).subscribe((res) => {
        this.loaderService.hide();
        resolve(res);
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      }
      );
    });
  }

  createDriver(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadsService.createDriver(data).subscribe(res => {
        this.loaderService.hide();
        resolve(data);
      }, error => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      })
    });
  }

  addUser(data: Array<any>): Promise<any> {
    this.loaderService.show();
    return new Promise<any>((resolve, reject) => {
      this.usersPageService.addUser(data).subscribe(res => {
        resolve(data);
        this.loaderService.hide();
      }, error => {
        reject(error);
        this.loaderService.hide();
      })
    });
  }

  updateUser(data: Array<any>, userID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.usersPageService.editUser(data, userID).subscribe(res => {
        this.loaderService.hide();
        resolve(res);
      }, error => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      });
    });
  }

  prepareDeleteConnections(): void {
    const dialogRef = this.dialog1.open(DeleteConnectionsComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({}, popup => {
      switch (popup.type) {
        case "close":
          componentInstance.close();
          break;
        case "ok":
          this.openDeleteDialog("Are you sure you want to delete this connection ? This action cannot be undone.").then(response => {
            if (response) {
              this.loaderService.show();
              this.connService.deleteConnections(popup.ids).subscribe((counter) => {
                const toastText: string = counter === 1 ? "Succesfully deleted connection!" : `Succesfully deleted ${counter} connections!`
                this.notificationService.success("Succes", toastText);
                componentInstance.getConnections();
              }, error => {
                this.loaderService.hide();
                console.log(error)
              });
            }
          });
          break;
      }
    });
  }

  openDeleteDialog(deleteText: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      const deletIconStyle = { stroke: '#D92D20', width: '48px', heigth: '48px' };
      const btn1Style = { background: 'white', color: '#344054', border: '1px solid #D0D5DD' };
      const btn2Style = { background: '#D92D20', color: 'white', border: '1px solid #D92D20' };

      const dialogData: any = {
        title: 'Delete',
        text: deleteText,
        button1Text: 'Cancel',
        button2Text: 'Delete',
        icon: 'custom-trash',
        iconStyle: deletIconStyle,
        dsa: true
      };

      const dialogRef = this.dialog.openDialog(DialogComponent, {
        props: { ...dialogData, btn1Style, btn2Style }
      });

      dialogRef.subscribe(dialog => {
        if (!dialog) return;
        switch (dialog.result) {
          case 'button1': resolve(false); break;
          case 'button2': resolve(true); break;
        }
      });
    });
  }

  getCheckedDrivers(): any[] {
    let allCheckedDrivers: any[] = []
    if (this.driversDisplay === 'list') {
      allCheckedDrivers = this.drivers.filter(driver => driver.checked)
    } else if (this.driversDisplay === 'both') {
      const driversFromList = this.drivers.filter(driver => driver.checked)
      const driversFromBlocks = this.driverBlocks.flatMap(block => block.array).filter(driver => driver.checked)
      allCheckedDrivers = driversFromList.concat(driversFromBlocks)
    }
    return allCheckedDrivers
  }

  getCheckedDispatchers(): any[] {
    let allCheckedDispatchers: any[] = []
    if (this.dispatchersDisplay === 'list') {
      allCheckedDispatchers = this.dispatchers.filter(dispatcher => dispatcher.checked)
    } else if (this.dispatchersDisplay === 'blocks') {
      const dispatchers = this.dispatcherBlocks.flatMap(block => block.array)
      allCheckedDispatchers = dispatchers.filter(dispatcher => dispatcher.checked)
    }
    return allCheckedDispatchers
  }

  // private getAllShares(): any[] {
  //   const users: any[] = [...structuredClone(this.allSupervisors), ...structuredClone(this.allDispatchers)].map(user => { 
  //     return {
  //       name: user.Name, 
  //       id: user.id, 
  //       role: user.RoleName, 
  //       roleCode: user.RoleCode, 
  //       receivedDriverShares: user.receivedDriverShares, 
  //       sentDriverShares: user.sentDriverShares 
  //     }
  //   })
  //   return users
  // }

  private getDriversCount(): number {
    let count = 0
    if (this.driversDisplay === 'list') {
      count = this.drivers.length
    } else if (this.driversDisplay === 'both') {
      count = this.driverBlocks.flatMap(block => block.array).length
    }
    return count
  }

  private getDispatchersCount(): number {
    let count = 0
    if (this.dispatchersDisplay === 'list') {
      count = this.dispatchers.length
    } else if (this.dispatchersDisplay === 'both') {
      count = this.dispatcherBlocks.flatMap(block => block.array).length
    }
    return count
  }

  private getReassignMessage(result: any): string {
    let text = ''

    if (result.driver) {
      text = `You\'ve reassigned the driver ${result.driver.Name} to the `
      if (result.dispatcher) {
        text = `You\'ve reassigned the driver ${result.driver.Name} to the dispatcher ${result.dispatcher.Name}`
      } else if (result.supervisor) {
        text = `You\'ve reassigned the driver ${result.driver.Name} to the supervisor ${result.supervisor.Name}`
      }
    } else if (result.dispatcher) {
      text = `You\'ve reassigned the dispatcher ${result.dispatcher.Name} to the `
      if (result.supervisor) {
        text = `You\'ve reassigned the dispatcher ${result.dispatcher.Name} to the supervisor ${result.supervisor.Name}`
      }
    }
    return text
  }
}